var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-container", [
    _c("div", [_c("PageDeveloperCompaniesDetailv2")], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }